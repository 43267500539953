import { StepConfirmation } from './step-confirmation';
import { StepEnrichment } from './step-enrichment';
import { StepFailed } from './step-failed';
import { StepPayment } from './step-payment';
import { StepProcessing } from './step-processing';
import { StepSuccess } from './step-success';

export const Steps = {
  Enrichment: StepEnrichment,
  Processing: StepProcessing,
  Confirmation: StepConfirmation,
  Payment: StepPayment,
  Failed: StepFailed,
  Success: StepSuccess,
};
