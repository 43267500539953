export const MainPage = () => {
  return (
    <div>
      <nav className="fixed start-0 top-0 z-20 w-full border-b border-gray-200 bg-white">
        <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
          <a
            href="https://flowbite.com/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <span className="self-center whitespace-nowrap text-2xl font-semibold text-brand-1">
              BNPL
            </span>
          </a>
          <div className="flex space-x-3 md:order-2 md:space-x-0">
            <a
              type="button"
              href="https://lk-client.bnpl.msk.finmoll.com/"
              className="block rounded px-3 py-2  text-brand-1 hover:underline"
              // className="w-fit rounded-lg bg-brand-2 px-4 py-2 text-center text-xl font-medium text-white hover:bg-brand-2/50 focus:outline-none"
            >
              Личный кабинет
            </a>
          </div>
          <div
            className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
            id="navbar-sticky"
          >
            <ul className="md:0 mt-4 flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 font-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-white md:p-0 rtl:space-x-reverse">
              {[
                { text: 'О нас', link: '/' },
                { text: 'Партнерам', link: '/' },
                { text: 'FAQ', link: '/' },
              ].map((item) => (
                <li>
                  <a
                    href={item.link}
                    className="block rounded px-3 py-2  text-brand-1 hover:underline"
                    aria-current="page"
                  >
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      <div className="size-full flex-center"></div>
    </div>
  );
};
