import { AxiosError } from 'axios';

import { toast } from 'react-toastify';

import { onAxiosError } from 'shared/lib/on-axios-error';

const beServiceErrorSpec = [
  { code: 1, message: 'Сущность с данными параметрами не найдена' },
  { code: 2, message: 'Сущность с данными параметрами уже существует' },
  { code: 3, message: 'Внутренняя ошибка' },
  { code: 4, message: 'Ошибка при обработке данных' },
  { code: 5, message: 'Неверный/отсутствующий id сессии' },
  { code: 6, message: 'Неверный/отсутствующий залоловок X-Request-Link' },
  { code: 7, message: 'ID сессии для этой ссылки не совпадает' },
  { code: 9, message: 'Ошибка при отправке кода подтверждения' },
  { code: 10, message: 'Код уже был отправлен' },
  { code: 11, message: 'Ошибка при подтверждении кода' },
  { code: 12, message: 'Срок действия кода подтверждения истек' },
  { code: 13, message: 'Заявка не найдена' },
  { code: 14, message: 'Закончились попытки проверки кода' },
  { code: 15, message: 'СМС не найдена' },
  { code: 16, message: 'Срок действия кода истек' },
  { code: 17, message: 'Истекли попытки отправки смс на этот номер' },
  { code: 18, message: 'Неверный код подтверждения' },
  { code: 19, message: 'Данный код подтверждения уже был подтвержден ранее' },
  { code: 20, message: 'Ссылка на заказ не действительна' },
  { code: 21, message: 'Заказ по ссылке не найден' },
  { code: 22, message: 'Ссылка на заказ устарела' },
];

export const onFeServiceError = (
  error: AxiosError & {
    response: {
      data: {
        errorCode: number;
        errorMessage: string;
      };
    };
  },
  callback: () => void,
) => {
  if (error.response.data.errorCode) {
    const serviceError = beServiceErrorSpec.find(
      ({ code }) => code === error.response!.data.errorCode,
    );
    if (serviceError) {
      if (serviceError?.code === 18) {
        toast.error(error.response.data.errorMessage);
        return;
      }
      localStorage.setItem('error', JSON.stringify(serviceError));
      callback();
    }
  }
  return onAxiosError(error);
};
