import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { StepType } from 'pages/order-page/ui';
import { Step } from 'pages/order-page/ui';

import { OrderApi } from 'entities/order';

import { onFeServiceError } from 'shared/lib';
import { Spinner } from 'shared/ui';

export const StepPayment = (props: { setStep: (step: StepType) => void }) => {
  const { orderId = '' } = useParams<{ orderId: string }>();

  const {
    data: status,
    isError: isStatusError,
    error: statusError,
  } = useQuery({
    queryKey: ['AWAITING_PAYMENT'],
    queryFn: () => OrderApi.checkAwaitingStatus(orderId, 'AWAITING_PAYMENT'),
  });

  useEffect(() => {
    if (isStatusError) {
      onFeServiceError(statusError as any, () => props.setStep(Step.Failed));
    }
  }, [isStatusError]);

  useEffect(() => {
    if (status === 'AWAITING_DECISION') {
      props.setStep(Step.Processing);
    }
  }, [status]);

  return (
    <>
      <p className=" my-4 text-center text-xl font-normal md:text-3xl">
        Ожидаем оплату по заказу
      </p>
      <div className="relative mx-auto size-[168px] rounded-full bg-[#D9D9D9]">
        <Spinner className="absolute inset-0" />
      </div>
    </>
  );
};
