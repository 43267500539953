import { cx } from '@emotion/css';

import type { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

export const HomeButton = ({
  className,
}: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <Link
      to="/"
      className={cx(
        'cursor-pointer mx-auto size-fit gap-2 self-end rounded-2xl bg-black py-4 text-white flex-center md:w-56',
        className,
      )}
    >
      На главную
    </Link>
  );
};
