export const Step = {
  Credentials: 'Credentials',
  Confirmation: 'Confirmation',
  Failed: 'Failed',
  Enrichment: 'Enrichment',
  Payment: 'Payment',
  Processing: 'Processing',
  Success: 'Success',
} as const;

export type StepType = (typeof Step)[keyof typeof Step];
