import { Checkbox } from 'antd';

import { useMutation } from '@tanstack/react-query';

import { OrderApi } from 'entities/order';

import { onAxiosError } from 'shared/lib';

export const ButtonDownloadAgreementPurchase = ({
  enabled,
  setEnabled,
  orderId,
}: {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  orderId: string;
}) => {
  const onDownloadPersonalDataMutation = useMutation({
    mutationFn: () => OrderApi.downloadPersonalData(orderId),
    onSuccess: (blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'personal-data.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    },
    onError: onAxiosError,
  });

  return (
    <label className="flex cursor-pointer items-center gap-2">
      <Checkbox
        checked={enabled}
        onChange={(e) => setEnabled(e.target.checked)}
        className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-inset ring-white/15 data-[checked]:bg-white"
      >
        <svg
          className="stroke-white text-brand-1 opacity-0 group-data-[checked]:opacity-100"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M3 8L6 11L11 3.5"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Checkbox>
      <span className="flex items-center">
        Даю согласие на&nbsp;
        <button
          className="underline"
          onClick={(event) => {
            event.preventDefault();
            onDownloadPersonalDataMutation.mutate();
          }}
        >
          рамочный договор
        </button>
      </span>
    </label>
  );
};
