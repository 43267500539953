import { HomeButton } from 'pages/order-page/ui';

export const StepFailed = () => {
  const { message }: { errorCode: number; message: string } =
    localStorage.getItem('error') && JSON.parse(localStorage.getItem('error')!);

  return (
    <>
      <p className="my-4 text-center text-xl font-normal md:text-3xl">
        {message ? 'Ошибка' : 'Отказ'}
      </p>
      <div className="mx-auto size-[168px] rounded-full bg-[#D9D9D9]" />
      <p className="mx-auto my-4 max-w-[80%] text-center text-xl font-normal leading-[120%] md:text-2xl">
        {message ? message : 'К сожалению, мы не можем утвердить вашу заявку'}
      </p>
      <HomeButton />
    </>
  );
};
